body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


@media(min-width: 1201px) and (min-height: 753px) {
  html, body {
    overflow: hidden;
  }


.slide-background:nth-child(1) { z-index: 15; }
.slide-background:nth-child(2) { z-index: 14; }
.slide-background:nth-child(3) { z-index: 13; }
.slide-background:nth-child(4) { z-index: 12; }
.slide-background:nth-child(5) { z-index: 11; }
.slide-background:nth-child(6) { z-index: 10; }
.slide-background:nth-child(7) { z-index: 9; }
.slide-background:nth-child(8) { z-index: 8; }
.slide-background:nth-child(9) { z-index: 7; }
.slide-background:nth-child(10) { z-index: 6; }
.slide-background:nth-child(11) { z-index: 5; }
.slide-background:nth-child(12) { z-index: 4; }
.slide-background:nth-child(13) { z-index: 3; }
.slide-background:nth-child(14) { z-index: 2; }
.slide-background:nth-child(15) { z-index: 1; }
}
